import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b12bcdb2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "submitForm"
};
const _hoisted_2 = {
  class: "simid"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OrganizeSelect = _resolveComponent("OrganizeSelect");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_input = _resolveComponent("el-input");
  const _component_TypeSelect = _resolveComponent("TypeSelect");
  const _component_el_form = _resolveComponent("el-form");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[6] || (_cache[6] = $event => _ctx.show = $event),
    title: _ctx.title,
    onConfirmOk: _ctx.onConfirm,
    width: "508px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
      ref: "ruleForm",
      rules: _ctx.rules,
      model: _ctx.formData,
      "validate-on-rule-change": false,
      "label-width": "98px"
    }, {
      default: _withCtx(() => [!_ctx.activeCardId ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        label: "组织",
        prop: "holdid"
      }, {
        default: _withCtx(() => [_createVNode(_component_OrganizeSelect, {
          modelValue: _ctx.searchValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.searchValue = $event),
          id: _ctx.formData.holdid,
          isClick: false,
          isSpecify: 'org',
          activeValue: _ctx.activeValue,
          isDoubleClick: true,
          placeholder: '请选择组织',
          onHandleDoubleClick: _ctx.handleDoubleClick
        }, null, 8, ["modelValue", "id", "activeValue", "onHandleDoubleClick"])]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
        label: "设备名",
        prop: "objectname"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.objectname,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.formData.objectname = $event),
          placeholder: "请输入设备名"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "设备IMEI",
        prop: "mdtid"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.mdtid,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.formData.mdtid = $event),
          placeholder: "请输入设备IMEI"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "客户设备号",
        prop: "mdtid3"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.mdtid3,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.formData.mdtid3 = $event),
          placeholder: "请输入客户设备号"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "设备型号",
        prop: "deviceType"
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          type: "equipmentType",
          style: {
            "width": "100%"
          },
          modelValue: _ctx.formData.deviceType,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.formData.deviceType = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "流量卡号码",
        prop: "simid"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_input, {
          class: "w_100",
          modelValue: _ctx.formData.simid,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.formData.simid = $event),
          placeholder: "请输入流量卡号码"
        }, null, 8, ["modelValue"])])]),
        _: 1
      })]),
      _: 1
    }, 8, ["rules", "model"])])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}